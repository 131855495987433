import Header from '@/components/header'
import Footer from '@/components/footer'
import { EventBus } from '@/assets/static/event-bus.js'

export default {
  name: 'App',

  components: {
    Header,
    Footer
  },

  data() {
    return {}
  },

  methods: {}
}
