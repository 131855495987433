import Vue from 'vue'
import VueToast from 'vue-toast-notification'
import LottieVuePlayer from '@lottiefiles/vue-lottie-player'

import App from '@/views/app'
import router from '@/router'

import 'vue-toast-notification/dist/theme-sugar.css'
import '@/assets/static'

Vue.use(VueToast, {
  queue: true,
  position: 'top',
  duration: 3000,
  dismissible: true
})

Vue.use(LottieVuePlayer)

Vue.config.productionTip = true

new Vue({
  router,
  render: function (h) {
    return h(App)
  }
}).$mount('#app')
