import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior: () => ({
    y: 0
  }),
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/home')
    },
    {
      path: '/exploracion',
      name: 'exploration',
      component: () => import('../views/world')
    },
    {
      path: '/exploracion/:id',
      name: 'country',
      component: () => import('../views/country')
    },
    {
      path: '/exploracion/:country/:id',
      name: 'country',
      component: () => import('../views/state')
    },
    {
      path: '/exploracion/:country/:state/:id',
      name: 'country',
      component: () => import('../views/place')
    },
    /*
    {
      path: '/videojuegos',
      name: 'videogames',
      component: () => import('../views/videogames')
    },
    {
      path: '/videojuegos/:id',
      name: 'videogame',
      component: () => import('../views/videogame')
    },
    */
    {
      path: '/documentos/:uid',
      name: 'documents',
      component: () => import('../views/documents')
    },
    {
      path: '/wp-admin',
      name: 'security',
      component: () => import('../views/security')
    },
    {
      path: '/panel',
      name: 'security',
      component: () => import('../views/security')
    },
    {
      path: '/dashboard',
      name: 'security',
      component: () => import('../views/security')
    },
    {
      path: '*',
      name: 'notFound',
      component: () => import('../views/notFound')
    }
  ]
});
