export default {
  methods: {
    getBgImgStyle: function(src) {
      let style = '';

      style += 'background-image: url(' + src + ');';
      style += 'background-size: cover;';
      style += 'background-position: center center;';
      style += 'background-repeat: no-repeat;';

      return style;
    }
  }
}
