import axios from 'axios'
import panel from '@/config/panel'
import bgImg from '@/mixins/bgImg'
import { EventBus } from '@/assets/static/event-bus'

export default {
  name: 'Header',

  mixins: [
    bgImg
  ],

  components: {},

  data() {
    return {
      logoSrc: require('@/assets/img/logo.png'),

      menu: [
        {
          to: '/exploracion',
          name: 'Exploración'
        },
        /*
        {
          to: '/videojuegos',
          name: 'Videojuegos'
        }
        */
      ]
    }
  },

  methods: {
    getPanelData: async function() {

    }
  },

  mounted: async function() {
    await this.getPanelData();
  }
}
