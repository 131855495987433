import axios from 'axios'
import panel from '@/config/panel'
import bgImg from '@/mixins/bgImg'
import { EventBus } from '@/assets/static/event-bus'

import StoreIcon from '@/components/icons/store'
import FacebookIcon from '@/components/icons/social/facebook'
import TwitterIcon from '@/components/icons/social/twitter'
import InstagramIcon from '@/components/icons/social/instagram'
import YoutubeIcon from '@/components/icons/social/youtube'

export default {
  name: 'Footer',

  mixins: [
    bgImg
  ],

  components: {
    StoreIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    YoutubeIcon
  },

  data() {
    return {
      logoSrc: require('@/assets/img/logo.png'),

      docs: [],

      store: 'https://www.shop.vidarware.com',
      social: {
        facebook: 'https://www.facebook.com/vidarwareMX',
        instagram: 'https://www.instagram.com/vidarware_mx',
        twitter: 'https://twitter.com/vidarware_mx',
        youtube: 'https://www.youtube.com/channel/UCFDZoTxfHmw88xeqb6Vh4bA'
      }
    }
  },

  methods: {
    getPanelData: async function() {
      let response;

      await axios
        .get(panel + '/footer.json')
        .then(res => response = res)
        .catch(err => console.log(err));

      if (typeof response !== 'undefined' && response.status == 200) {
        this.docs = response.data;
      }
    }
  },

  mounted: async function() {
    await this.getPanelData();
  }
}
